import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../contexts/LanguageContext";
// import { breakpoints } from "../styles/constants";
import { useLocation } from "react-router-dom";

type Season = "spring" | "summer" | "autumn" | "winter";
type Language = "ko" | "en";

interface TourInfo {
  title: string;
  subtitle: string;
  period: string;
  price: string;
  highlights: string[];
  includes: string[];
  description: string;
}

interface SeasonTourInfo {
  spring: TourInfo;
  summer: TourInfo;
  autumn: TourInfo;
  winter: TourInfo;
}

interface SeasonTours {
  ko: SeasonTourInfo;
  en: SeasonTourInfo;
}

interface SeasonImages {
  spring: string[];
  summer: string[];
  autumn: string[];
  winter: string[];
}

const Tour = () => {
  const location = useLocation();
  const { language } = useLanguage();
  const [selectedSeason, setSelectedSeason] = useState<Season>(
    (location.state?.selectedSeason as Season) || "spring"
  );
  const [currency, setCurrency] = useState<"USD" | "PHP" | "KRW">("KRW");

  useEffect(() => {
    if (location.state?.selectedSeason === "winter") {
      const winterSection = document.getElementById("winter-section");
      if (winterSection) {
        winterSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  const seasonImages: SeasonImages = {
    spring: [
      "/images/spring-1.jpg",
      "/images/spring-2.jpg",
      "/images/spring-3.jpg",
    ],
    summer: [
      "/images/summer-1.jpg",
      "/images/summer-2.jpg",
      "/images/summer-3.jpg",
    ],
    autumn: [
      "/images/autumn-1.jpg",
      "/images/autumn-2.jpg",
      "/images/autumn-3.jpg",
    ],
    winter: [
      "/images/9-9.webp",
      "/images/winter-2.jpg",
      "/images/winter-3.jpg",
    ],
  };

  const tours: SeasonTours = {
    en: {
      spring: {
        title: "Spring Season Tour",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      summer: {
        title: "Summer Season Tour",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      autumn: {
        title: "Autumn Season Tour",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      winter: {
        title: "Winter Season Tour",
        subtitle: "G-WAGON WINTER TOUR",
        period: "December 11 - 18, 2024",
        price: "499,000",
        highlights: [
          "Stay at 5-star luxury hotels",
          "Various activities (Ski & Snowboard, Seoul Night City Drive, etc.)",
          "Cultural and sightseeing experiences (Gangneung Traditional Market, Manhangjae uphill drive. etc.)",
          "Michelin-starred restaurant dinner (Seoul), and more",
        ],
        includes: [
          "Airport pickup and drop-off (private vehicle provided)",
          "KTX (Bullet Train) ticket",
          "4WD luxury SUV (G-Wagen 63AMG)",
          "5-star hotels and resorts with breakfast included during the itinerary",
          "Activities and sightseeing",
        ],
        description:
          "Experience the premium driving in the snowy winter. Enjoy the special tour with winter leisure and luxury.",
      },
    },
    ko: {
      spring: {
        title: "봄 시즌 투어",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      summer: {
        title: "여름 시즌 투어",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      autumn: {
        title: "가을 시즌 투어",
        subtitle: "Coming Soon",
        period: "",
        price: "",
        highlights: [],
        includes: [],
        description: "",
      },
      winter: {
        title: "겨울 시즌 투어",
        subtitle: "G-WAGON 겨울 투어",
        period: "2024년 12월 11일 - 18일",
        price: "499,000",
        highlights: [
          "5성급 럭셔리 호텔 투숙",
          "다양한 액티비티 (스키 & 스노보드, 서울 야경 드라이브 등)",
          "문화 및 관광 체험 (강릉 전통시장, 마흔재 오르막 드라이브 및 운암정 베이커리, 보발재 전망대 등)",
          "미슐랭 스타 레스토랑 디너(서울) 등",
        ],
        includes: [
          "공항 픽업 및 드롭(전용 차량 제공)",
          "KTX(고속열차) 티켓",
          "4륜 구동 럭셔리 SUV (G바겐 63AMG)",
          "일정 내 5성급 호텔 및 리조트 숙박(조식 포함)",
          "액티비티 및 관광",
        ],
        description:
          "하얀 설경 속 프리미엄 드라이빙 체험. 겨울 레저와 럭셔리한 휴식이 함께하는 특별한 투어입니다.",
      },
    },
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const renderHighlights = (highlight: string, index: number): JSX.Element => (
    <ListItem key={index}>
      <Dot />
      {highlight}
    </ListItem>
  );

  const renderIncludes = (item: string, index: number): JSX.Element => (
    <ListItem key={index}>
      <Dot />
      {item}
    </ListItem>
  );

  const buttonText = {
    ko: "투어 예약하기",
    en: "Book This Tour",
  };

  const phpToUsdRate = 0.01704; // 예시 환율: 1 PHP = 0.018 USD
  const phpToKrwRate = 23.74; // 예시 환율: 1 PHP = 23.5 KRW

  const priceInSelectedCurrency = (price: string) => {
    const priceInPhp = parseFloat(price.replace(/[^0-9.-]+/g, ""));
    switch (currency) {
      case "USD":
        return (
          (priceInPhp * phpToUsdRate).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) + " USD"
        );
      case "PHP":
        return priceInPhp.toLocaleString() + " PHP";
      case "KRW":
        return (
          (priceInPhp * phpToKrwRate).toLocaleString(undefined, {
            minimumFractionDigits: 0,
          }) + " KRW"
        );
      default:
        return price;
    }
  };

  return (
    <Container>
      <SliderWrapper>
        <StyledSlider {...sliderSettings}>
          {seasonImages[selectedSeason].map((image: string, index: number) => (
            <SlideImage key={index}>
              <img src={image} alt={`${selectedSeason} tour ${index + 1}`} />
            </SlideImage>
          ))}
        </StyledSlider>
      </SliderWrapper>

      <Title>SEASONAL TOURS</Title>
      <SeasonSelector>
        {(Object.keys(tours[language as Language]) as Season[]).map(
          (season) => (
            <SeasonButton
              key={season}
              isSelected={selectedSeason === season}
              onClick={() => setSelectedSeason(season)}
            >
              {tours[language as Language][season].title}
            </SeasonButton>
          )
        )}
      </SeasonSelector>

      <TourInfo>
        <SeasonTitle>
          {tours[language as Language][selectedSeason].subtitle}
        </SeasonTitle>
        <Period>{tours[language as Language][selectedSeason].period}</Period>
        <Price>
          {priceInSelectedCurrency(
            tours[language as Language][selectedSeason].price
          )}
        </Price>

        <CurrencySelector>
          <CurrencyButton onClick={() => setCurrency("USD")}>
            USD
          </CurrencyButton>
          <CurrencyButton onClick={() => setCurrency("PHP")}>
            PHP
          </CurrencyButton>
          <CurrencyButton onClick={() => setCurrency("KRW")}>
            KRW
          </CurrencyButton>
        </CurrencySelector>

        <Section>
          <SectionTitle>Tour Highlights</SectionTitle>
          <List>
            {tours[language as Language][selectedSeason].highlights.map(
              renderHighlights
            )}
          </List>
        </Section>

        <Section>
          <SectionTitle>Includes</SectionTitle>
          <List>
            {tours[language as Language][selectedSeason].includes.map(
              renderIncludes
            )}
          </List>
        </Section>

        <Description>
          {tours[language as Language][selectedSeason].description}
        </Description>

        <ButtonContainer>
          {selectedSeason === "winter" && (
            <StyledLink
              href="/assets/G-Wagon_Winter_Tour(Client).pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BookButton>More Details</BookButton>
            </StyledLink>
          )}
          <BookButton>{buttonText[language as Language]}</BookButton>
        </ButtonContainer>
      </TourInfo>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #fff;
  padding: 120px 20px;
`;

const Title = styled.h1`
  /* font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */

  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  font-family: "Gruppo", sans-serif;
  margin-bottom: 6cap;
  margin-top: 100px;
`;

const SeasonSelector = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const SeasonButton = styled.button<{ isSelected: boolean }>`
  padding: 1rem 2rem;
  background: ${({ isSelected }) =>
    isSelected ? "rgba(255, 215, 0, 0.1)" : "transparent"};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "#ffd700" : "rgba(255, 255, 255, 0.3)")};
  color: ${({ isSelected }) => (isSelected ? "#ffd700" : "#fff")};
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: #ffd700;
    color: #ffd700;
  }
`;
//eslint-disable-next-line
const TourInfo = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 3rem;
  border: 1px solid rgba(255, 215, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
`;

const SeasonTitle = styled.h2`
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
  text-align: center;
`;

const Period = styled.p`
  text-align: center;
  color: #ccc;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Price = styled.p`
  text-align: center;
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
`;

const Section = styled.div`
  margin: 2rem 0;
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
  padding-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  color: #fff;
  font-size: 1.1rem;
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: #ffd700;
  border-radius: 50%;
  margin-right: 1rem;
`;

const Description = styled.p`
  color: #ccc;
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 2rem 0;
  text-align: center;
  font-style: italic;
`;

const BookButton = styled.button`
  display: block;
  width: 100%;
  max-width: 150px;
  margin: 0;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(135deg, #ffd700, #ffa500);
  border: none;
  border-radius: 50px;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (min-width: 769px) {
    max-width: 300px;
    font-size: 1.2rem;
  }
`;

const StyledLink = styled.a`
  text-decoration: none; // 밑줄 제거
`;

const SliderWrapper = styled.div`
  margin: -120px -20px 3rem -20px;
  height: 60vh;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to top, #000000, transparent);
    pointer-events: none;
  }
`;

const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-dots {
    bottom: 30px;
    z-index: 1;

    li button:before {
      color: #ffd700;
    }

    li.slick-active button:before {
      color: #ffd700;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;

    &:before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }
`;

const SlideImage = styled.div`
  height: 60vh;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const CurrencySelector = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const CurrencyButton = styled.button`
  margin-left: 5px;
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #ffd700;
  color: #ffd700;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 215, 0, 0.1);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export default Tour;

import React, { useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/LanguageContext";
import { breakpoints } from "../styles/constants";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaClock,
  FaInstagram,
} from "react-icons/fa";
import Img9 from "../assets/7.jpg";

const translations = {
  ko: {
    title: "문의하기",
    subtitle: "프리미엄 여행 경험을 위한 상담을 도와드립니다",
    form: {
      name: "이름",
      email: "이메일",
      phone: "연락처",
      season: "희망 투어 시즌",
      participants: "인원 수",
      vehicle: "희망 차량",
      message: "문의 내용",
      privacy: "개인정보 수집 및 이용에 동의합니다",
      submit: "준비중",
    },
    seasons: {
      spring: "봄",
      summer: "여름",
      autumn: "가을",
      winter: "겨울",
    },
    vehicles: {
      sedan: "세단",
      suv: "SUV",
      van: "밴",
    },
  },
  en: {
    title: "Contact Us",
    subtitle: "Let us help you plan your premium travel experience",
    form: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      season: "Preferred Season",
      participants: "Number of Participants",
      vehicle: "Preferred Vehicle",
      message: "Message",
      privacy: "I agree to the privacy policy",
      submit: "Coming Soon!",
      // submit: "Submit",
    },
    seasons: {
      spring: "Spring",
      summer: "Summer",
      autumn: "Autumn",
      winter: "Winter",
    },
    vehicles: {
      sedan: "Sedan",
      suv: "SUV",
      van: "Van",
    },
  },
};

const Container = styled.div`
  width: 100%;
`;

const HeroSection = styled.div`
  height: 60vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Img9});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 300;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.3rem;
  }
`;

const ContentSection = styled.div`
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const ContactInfo = styled.div`
  padding: 2.5rem;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  svg {
    font-size: 1.5rem;
    color: #ffa730;
    margin-right: 1rem;
  }
`;

const InfoText = styled.div`
  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
  }

  p {
    color: #666;
    font-size: 1rem;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2.5rem;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ffa730;
    box-shadow: 0 0 0 3px rgba(255, 167, 48, 0.1);
  }
`;

const Select = styled.select`
  flex: 1;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ffa730;
    box-shadow: 0 0 0 3px rgba(255, 167, 48, 0.1);
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #ffa730;
    box-shadow: 0 0 0 3px rgba(255, 167, 48, 0.1);
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  background: #ffa730;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #ff9610;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 167, 48, 0.3);
  }
`;

const Contact = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    season: "",
    participants: "",
    vehicle: "",
    message: "",
    privacy: false,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <Container>
      <HeroSection>
        <HeroContent>
          <Title>{t.title}</Title>
          <Subtitle>{t.subtitle}</Subtitle>
        </HeroContent>
      </HeroSection>

      <ContentSection>
        <ContactInfo>
          <InfoItem>
            <FaPhone />
            <InfoText>
              <h3>전화</h3>
              <p>Coming Soon</p>
            </InfoText>
          </InfoItem>
          <InfoItem>
            <FaEnvelope />
            <InfoText>
              <h3>이메일</h3>
              <p>support@momentokorea.com</p>
            </InfoText>
          </InfoItem>
          <InfoItem>
            <FaMapMarkerAlt />
            <InfoText>
              <h3>주소</h3>
              <p>Coming Soon</p>
            </InfoText>
          </InfoItem>
          <InfoItem>
            <FaClock />
            <InfoText>
              <h3>운영시간</h3>
              <p>월-금: 09:00 - 18:00</p>
              <p>토-일: 예약제 운영</p>
            </InfoText>
          </InfoItem>
          <InfoItem>
            <FaInstagram />
            <InfoText>
              <h3>Instagram</h3>
              <p>@momento_korea</p>
            </InfoText>
          </InfoItem>
        </ContactInfo>

        <ContactForm onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              type="text"
              placeholder={t.form.name}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <Input
              type="email"
              placeholder={t.form.email}
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </FormGroup>

          <FormGroup>
            <Input
              type="tel"
              placeholder={t.form.phone}
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
            <Select
              value={formData.season}
              onChange={(e) =>
                setFormData({ ...formData, season: e.target.value })
              }
            >
              <option value="">{t.form.season}</option>
              <option value="spring">{t.seasons.spring}</option>
              <option value="summer">{t.seasons.summer}</option>
              <option value="autumn">{t.seasons.autumn}</option>
              <option value="winter">{t.seasons.winter}</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Input
              type="number"
              placeholder={t.form.participants}
              value={formData.participants}
              onChange={(e) =>
                setFormData({ ...formData, participants: e.target.value })
              }
            />
            <Select
              value={formData.vehicle}
              onChange={(e) =>
                setFormData({ ...formData, vehicle: e.target.value })
              }
            >
              <option value="">{t.form.vehicle}</option>
              <option value="sedan">{t.vehicles.sedan}</option>
              <option value="suv">{t.vehicles.suv}</option>
              <option value="van">{t.vehicles.van}</option>
            </Select>
          </FormGroup>

          <TextArea
            placeholder={t.form.message}
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />

          <SubmitButton type="submit">{t.form.submit}</SubmitButton>
        </ContactForm>
      </ContentSection>
    </Container>
  );
};

export default Contact;
